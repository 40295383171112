/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

/* Layout */
@import "assets/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Quill Text Editor for p-editor */
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

.queue-modal {
    width: 80%;
    .p-dialog-header {
        .p-dialog-title {
            flex-grow: 1;
            text-align: center;
        }
    }

    .p-dialog-content {
        padding-bottom: 0px !important;
    }
}

p-steps {
    position: sticky;
    top: -28px;
    background: #fff;
    z-index: 9;

    nav {
        background-color: #fff;

        ul {
            li {
                a {
                    .p-steps-number {
                        display: none;
                    }

                    .p-steps-title {
                        padding: 10px 25px;
                    }
                }

                &::before {
                    bottom: 0px;
                    top: unset !important;
                }

                .p-steps-title {
                    font-weight: 700;
                    color: green;

                    &:before {
                        content: "✔";
                        background: white;
                        margin-left: -3px;
                        position: absolute;
                    }
                }
            }

            .p-highlight {
                .p-steps-title {
                    font-weight: 700;
                    color: #4297ff !important;
                    border-bottom: 3px solid #4297ff;

                    &:before {
                        content: unset;
                    }
                }
            }

            .p-highlight~* {
                .p-steps-title {
                    color: #6c757d !important;

                    &:before {
                        content: unset;
                    }
                }
            }
        }
    }
}

input[type='checkbox'] {
    width: 15px;
    height: 15px;
}

.formgrid {
    .btns-wrapper {
        border-top: 1px solid #dee2e6;
    }
}

.counter-main {
    background: #4c63b6;
    padding: 1px 5px;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 10px;
    font-size: 11px;
    gap: 5px;
    min-width: 56px;
    margin: auto;

    .counter {
        padding-top: 2px;
        cursor: pointer;

        i {
            font-size: 12px;
        }
    }
}

.warning-checkbox {
    min-width: 75px;
}